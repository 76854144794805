<template>
     <el-dialog
        v-model="call_log_dailog"
        title="Create Call Log"
        class="appointment-status-modal"
        size="lg"
        width="70%" draggable>
        <CallLogForm @dialogClose="call_log_dailog=false" :patient_id="patient_id"  :log_id="log_id" :inboundOnly="inboundOnly" @callApi="callApi"/>
        
        <div class="mt-30">
          <h3>Recent Call logs:</h3>
          <el-table :data="call_lists" border style="width: 100%" v-loading="loading">
            <!-- <el-table-column prop="fullname" label="Fullname"></el-table-column> -->

            <el-table-column prop="call_category" width="180" align="center" label="Call category"></el-table-column>
            <el-table-column prop="call_type" label="Call type" align="center"></el-table-column>
            <el-table-column prop="call_action_status" label="Call action status" align="center">
                <template #default="scope">
                    <el-tag class="ml-2" :type="scope.row.call_action_status == 'pending' ? 'warning' : 'danger'">{{  scope.row.call_action_status }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="remarks" label="Remarks" align="center"></el-table-column>
            <el-table-column prop="assigned_crm_name" label="Assigned To" align="center"></el-table-column>
            <el-table-column prop="call_action_date" label="Called at" align="center"></el-table-column>
          </el-table>
        </div>

    </el-dialog>
</template>
<script>
import CallLogForm from '../form/CallLogForm.vue';
import axios from 'axios';
export default {
  name: 'CallLogModal',
  props: {
    patient_id: {
      type: Number,
      required: false,
    },
    log_id: {
        type: Number,
        required: false,
    },
    inboundOnly: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    CallLogForm,
  },
  data() {
    return {
      call_log_dailog: false,
      call_lists: [],
    };
  },
  created() {
    this.call_log_dailog = true;
    this.getCallList();
  },
  methods: {
    getCallList(){
      axios.get(`/api/v2/agent/call-logs/patients/${this.patient_id}?limit=3`).then(res => {
          this.call_lists = res.data.data;
      })
    }
  },
  callApi(){
    this.$emit('allApi');
  },
  watch: {
    call_log_dailog(oldValue, newValue) {
      if (!oldValue) {
        this.$emit('modalClosed');
      }
    },
  },
};
</script>
