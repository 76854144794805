<template>
    <el-form
    label-position="top">
    <el-row :gutter="20">
      <el-col :span="8" v-if="inboundOnly">
          <el-form-item label="Patient name" prop="patient_name">
              <el-input v-model="form.patient_name" placeholder="Patient name" />
          </el-form-item>
      </el-col>
      <el-col :span="8" v-if="inboundOnly">
          <el-form-item label="Phone Number" prop="phone_number">
            <el-input v-model="form.phone_number" placeholder="Phone No" >
              <template #prepend>+88</template>
            </el-input>
          </el-form-item>
      </el-col>
      <el-col :span="8" v-if="inboundOnly">
        <el-form-item label="Gender" prop="gender">
          <el-select v-model="form.gender" class="w-100">
            <el-option label="Male" value="male"/>
            <el-option label="Female" value="female"/>
          </el-select>
        </el-form-item>
      </el-col>
        <el-col :span="8">
            <el-form-item label="Call Response" prop="call_response">
                <el-select v-model="form.call_response" class="w-100">
                    <el-option label="Yes" :value="1" />
                    <el-option label="No" :value="0" />
                </el-select>
            </el-form-item>
      </el-col>
      <el-col :span="8" v-if="!inboundOnly">
            <el-form-item label="Call Type" prop="call_type">
                <el-select v-model="form.call_type" class="w-100">
                    <el-option label="Inbound" value="inbound" />
                    <el-option label="Outbound" value="outbound" />
                </el-select>
            </el-form-item>
      </el-col>
      <el-col :span="8" v-if="form.call_type == 'inbound'">
            <el-form-item label="Call Source" prop="call_source">
                <el-select v-model="form.call_source" class="w-100">
                    <el-option label="Social" value="Social" />
                    <el-option label="Sticker" value="Poster" />
                    <el-option label="Doctor Referral" value="Doctor Referral" />
                    <el-option label="Patient Referral" value="Patient Referral" />
                    <el-option label="Employee Referral" value="Employee Referral" />
                    <el-option label="NGO" value="NGO" />
                    <!-- <el-option label="Others" value="Others" /> -->
                </el-select>
            </el-form-item>
      </el-col>
      <el-col :span="8">
            <el-form-item label="Call Category" prop="call_category">
                <el-select v-model="form.call_category_id" class="w-100">
                    <el-option
                        v-for="item in call_categories"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id"
                    />
                </el-select>
            </el-form-item>
      </el-col>
      <el-col :span="8">
            <el-form-item label="Call Status" prop="call_status">
                <el-select v-model="form.call_action_status_id" class="w-100">
                    <el-option
                        v-for="item in call_status"
                        :key="item.id"
                        :label="item.title"
                        :value="item.id"
                    />
                </el-select>
            </el-form-item>
      </el-col>
      <el-col :sm="24" :md="8" :lg="8">
          <el-form-item label="Calling Purpose" prop="reg_purpose" :rules="[
                  {
                    required: true,
                    message: 'Please Select Calling Purpose',
                    trigger: 'blur',
                  }
                ]">
            <el-select
                @change="getRegistrationPurpose"
                class="w-100"
                v-model="form.reg_purpose"
                clearable
                filterable
                placeholder="Select Calling Purpose"
                required
            >
              <el-option
                  v-for="item in RegistrationPurpose"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      <el-col :span="8">
        <el-checkbox v-model="form.has_reminder" label="Want a reminder" size="large" class="mt-5"/>
        <div v-if="form.has_reminder">
            <el-date-picker
                class="w-100"
                v-model="form.reminder_date"
                type="date"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
            />
        </div>
      </el-col>
      <el-col :span="8">
        <el-form-item label="Remarks" prop="remarks">
            <el-input v-model="form.remarks" type="textarea" placeholder="Referred by or others ..." />
        </el-form-item>
      </el-col>
      <br/>
      <el-col :span="24">
        <el-button @click="closeModal">Cancel</el-button>
        <el-button type="primary" @click="submitForm('form')">
          Confirm
        </el-button>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'CallLogForm',
  props: {
    patient_id: {
      type: Number,
      required: false,
    },
    log_id: {
        type: Number,
        required: false,
    },
    inboundOnly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      form: {
        user_id: '',
        call_response: 1,
        call_type: 'outbound',
        call_source: '',
        reminder_date: null,
        remarks: '',
        call_category_id: '',
        call_action_status_id: '',
        has_reminder: false,
        agent_username: '',
        assigned_to: '',
        reg_purpose: '',
      },
      call_categories: [],
      call_status: [],
      RegistrationPurpose: [
          { label: "ZCF", value: "ZCF" },
          { label: "PHT", value: "PHT" },
          { label: "Dental", value: "Dental" },
          { label: "CP", value: "CP" },
          { label: "Autism", value: "Autism" },
          { label: "Rickets/Bow Leg", value: "Rickets/Bow Leg" },
      ],
    };
  },
  created() {
    this.callCategory();
    this.callStatus();
    if(this.inboundOnly) {
      this.form.call_type = 'inbound';
    }
  },
  computed: {

    ...mapGetters('auth', ['authUser', 'userInstitute']),

  },
  methods: {
    getRegistrationPurpose(RegistrationPurpose) {
      const RegistrationPurposeObj = this.RegistrationPurpose.find((item) => item.label === RegistrationPurpose);
      return RegistrationPurposeObj.label;
    },
    callCategory() {
      axios.get('/api/v1/agent/call/category/list?call_type=outbound')
        .then((response) => {
          this.call_categories = response.data.data;
        });
    },
    callStatus() {
      axios.get('/api/v1/agent/call/action/status/list')
        .then((response) => {
          this.call_status = response.data.data;
        });
    },
    closeModal() {
      this.$emit('dialogClose');
    },
    submitForm() {

      if(this.inboundOnly && !this.form.patient_name) {
        this.$notify({
              title: 'Error!',
              message: 'Patient Name is required.',
              type: 'error',
              duration: 4000,
            });

            return false;   
      }

      if(this.inboundOnly && !this.form.gender) {
        this.$notify({
              title: 'Error!',
              message: 'Gender is required.',
              type: 'error',
              duration: 4000,
            });

            return false;   
      }

      if(this.inboundOnly && (!this.form.phone_number || !this.form.phone_number.match(/^[0-9]{11}$/))) {
        this.$notify({
              title: 'Error!',
              message: 'Phone No is required, must be 11 digits.',
              type: 'error',
              duration: 4000,
            });

            return false;   
      }
      
      if(!this.form.remarks) {
        this.$notify({
              title: 'Error!',
              message: 'Remarks field is required',
              type: 'error',
              duration: 4000,
            });

            return false;
      }

      if(!this.form.call_source && this.form.call_type == 'inbound') {
        this.$notify({
              title: 'Error!',
              message: 'Call Source is required',
              type: 'error',
              duration: 4000,
            });

            return false;
      }

      if(this.inboundOnly) {
        this.form.phone_number = '+88'+this.form.phone_number;  
      }
      

      this.form.user_id = this.patient_id?this.patient_id:'';
      this.form.assigned_to = this.authUser.id;
      let url = '';
      if(this.log_id) {
        url = `api/v2/agent/call-logs/${this.log_id}?_method=put`;
      }else {
        url = '/api/v1/agent/call/logs/store';
      }
      axios.post(url, this.form)
        .then((res) => {
          if (res.data.status_code == 200) {
            this.$emit('dialogClose');
            if(this.log_id) {
                this.$emit('callApi');
            }
            this.$notify({
              title: 'Success',
              message: 'Call log added successfully',
              type: 'success',
              duration: 2000,
            });
          } else {
            this.$notify({
              title: 'Failed',
              message: res.data.message,
              type: 'danger',
              duration: 2000,
            });
          }
        });
    },
  },
};
</script>
<style>
.w-100 {
    width: 100% !important;
}
</style>
