<template>
    <div>
     <div class="text-right" v-if="authUser.user_type == 'executive'">
      <el-button type="primary" size="large" @click="exportItems()">Export</el-button>
     </div>
        <el-row :gutter="20" align="middle" class="align-items-center">
            <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
                <p class="demonstration">Call response</p>
                <el-select v-model="filter.call_response" class="w-100" size="large">
                    <el-option label="Yes" value="1" />
                    <el-option label="No" value="0" />
                </el-select>
            </el-col>
            <!-- v-if="authUser.user_type  == 'crm-manager' -->
            <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" v-if="authUser.user_type == 'executive'">
            <p class="demonstration">Select CRM/GSO</p>
            <el-select v-model="filter.user_id" class="w-100" size="large">
                <el-option :label="user.fullname" :value="user.id"  v-for="(user, index) in users" :key="index"/>
            </el-select>

            </el-col>
        <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5">
            <p class="demonstration">Start date</p>
            <el-date-picker
                v-model="filter.start_date"
                type="date"
                class="w-100"
                placeholder="Pick a day"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                size="large"
            />
        </el-col>
        <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="5">
            <p class="demonstration">End date</p>
            <el-date-picker
                v-model="filter.end_date"
                type="date"
                class="w-100"
                placeholder="Pick a day"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                size="large"
            />
        </el-col>
        <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6" class="mt-40">
            <el-button type="primary" size="large" @click="getItems()" round>Filter</el-button>
            <el-button type="danger" size="large" @click="clear" round>Clear</el-button>
        </el-col>

        <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4" class="mt-40" style="text-align: right">
            <el-button type="warning" size="large" @click="call_log_dailog = true" round>Create Inbound Call</el-button>
        </el-col>
        </el-row>
        <br />
        <el-row>
            <el-col :span="24">
                <el-table :data="items" border style="width: 100%">
                    <el-table-column align="center" prop="fullname" label="Fullname" width="160"></el-table-column>
                    <el-table-column align="center" prop="user_phone" label="Phone No." width="145"></el-table-column>

                    <el-table-column align="center" prop="call_category" label="Call category" width="180"></el-table-column>
                    <el-table-column align="center" prop="call_type" label="Call type" width="130"></el-table-column>
                    <el-table-column align="center" prop="call_source" label="Call Source" width="110">
                      <template #default="scope">
                        {{ (scope.row.call_source == 'Poster')?'Sticker':scope.row.call_source }}
                      </template>
                    </el-table-column>
                    <el-table-column align="center" prop="call_action_status" label="Call action status" width="200">
                        <template #default="scope">
                            <el-tag class="ml-2" :type="scope.row.call_action_status == 'pending' ? 'warning' : 'danger'">{{  scope.row.call_action_status }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="remarks" label="Remarks" width="180"></el-table-column>
                    <el-table-column align="center" prop="assigned_crm_name" label="Assigned crm name" width="170"></el-table-column>
                    <el-table-column align="center" prop="call_action_date" label="Called at" width="135"></el-table-column>
                    <el-table-column align="center" prop="actions" class="text-center" label="Actions" width="140">
                        <template #default="scope">

                        <el-button type="danger" class="m-5" circle @click="createCall(scope.row)" v-if="scope.row.status== 'pending'">
                            <el-icon><PhoneFilled /></el-icon>
                        </el-button>

                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <el-row>
            <el-col
                :span="24"
                style="display: flex; justify-content: center; margin-top: 15px"
            >
                <el-pagination
                @current-change="pageChanged"
                :current-page="filter.currentPage"
                background
                layout="prev, pager, next"
                :total="total_count"
                :page-size="perPage"
                />
            </el-col>
        </el-row>
        <div>
            <CallLogModal v-if="call_log_dailog" :patient_id="call_details.user_id" @callApi="getItems()"  @modalClosed="call_log_dailog=false" :log_id="call_details.log_id" :inboundOnly="true"/>
        </div>
</div>
</template>
<script>
import axios from 'axios';
import CallLogModal from '../../components/modal/CallLogModal.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'CallLogList',
  data() {
    return {
      items: [],
      filter: {
        start_date: null,
        end_date: null,
        currentPage: 1,
        call_response: '',
        call_action_status: '',
        user_id: '',
      },
      call_log_dailog: false,
      call_details: {},
      total_count: 0,
      perPage: 20,
      users: [],
    };
  },
  components: {
    CallLogModal,
  },
  mounted() {
    this.getItems();
    this.setSearchParams();
    this.getUser();
  },
  computed: {
    ...mapGetters('auth', ['userInstitute', 'authUser']),
  },
  methods: {
    setSearchParams() {
      this.filter.currentPage = this.$route.query.currentPage
        ? parseInt(this.$route.query.currentPage)
        : 1;
    },
    getItems() {
      this.$router.replace({ query: this.filter });
      const offset = this.perPage * (this.filter.currentPage - 1);
      axios.get(`/api/v2/agent/call-logs?platform=occ&limit=${this.perPage}${offset ? `&offset=${offset}` : ''}${this.filter.start_date ? `&start_date=${this.filter.start_date}` : ''}${this.filter.end_date ? `&end_date=${this.filter.end_date}` : ''}${this.filter.call_action_status ? `&status=${this.filter.call_action_status}` : ''}${this.filter.call_response ? `&call_response=${this.filter.call_response}` : ''}${this.filter.user_id ? `&user_id=${this.filter.user_id}` : ''}`)
      .then((res) => {
        this.items = res.data.data;
        this.total_count = res.data.total_count;
      });
    },
    pageChanged(val) {
      this.filter.currentPage = val;
      this.getItems();
    },
    clear() {
      this.filter = {};
    },
    createCall(row) {
        console.log('row', row);
      this.call_details = row;
      this.call_log_dailog = true;
    },
    getUser() {
      axios.get(`/api/v1/user/list/orko-center`).then(res => {
        this.users = res.data.data;
      })
    },
    exportItems() {
      this.$router.replace({ query: this.filter });
      const offset = this.perPage * (this.filter.currentPage - 1);
      axios
        .get(
          `/api/v2/agent/call-logs/export?platform=occ&limit=${this.perPage}${offset ? `&offset=${offset}` : ''}${this.filter.start_date ? `&start_date=${this.filter.start_date}` : ''}${this.filter.end_date ? `&end_date=${this.filter.end_date}` : ''}${this.filter.call_action_status ? `&status=${this.filter.call_action_status}` : ''}${this.filter.call_response ? `&call_response=${this.filter.call_response}` : ''}${this.filter.user_id ? `&user_id=${this.filter.user_id}` : ''}`,
          { responseType: 'blob' },
        )
        .then((response) => {
          const filename = response.headers['content-disposition']
            .split('filename=')[1]
            .split('.')[0];
          const extension = response.headers['content-disposition']
            .split('.')[1]
            .split(';')[0];
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            }),
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${filename}.${extension}`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },

  },

};
</script>
<style>
.w-100 {
    width: 100% !important;
}
</style>
